<template>
  <div class="page">
    <div class="question-box">
      <span><img :src="info.qaAvatar">{{info.qaName}}</span>
      <div>提问：{{info.question}}</div>
    </div>
    <div class="comment-box">
            <textarea @input="handleInput" v-model="inputText" class="comment-box__input"
                      maxlength="500"
                      placeholder="回复提问"></textarea>
      <div class="comment-box__counter" v-if=" count >= 0 ">剩余 {{count}}</div>
      <div class="comment-box__counter warning" v-else>已超出 {{-count}}</div>
    </div>
    <div  v-if="info.answer !== null" class="answer-btn" >该问题已回答</div>
    <div v-else class="submit-btn" @click="onSubmitTap">提交</div>
  </div>
</template>

<script>
import { Field } from 'vant'
import { getQa, answer } from '@/api/qa'
import to from "@/utils/to";

export default {
  components: {
    [Field.name]: Field
  },
  data() {
    return {
      id: null,
      info: {},
      count: 500,
      inputText: ''
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getData();
  },
  methods: {
    async getData() {
      this.$showLoading("加载中...");
      let [err, res] = await to(getQa(this.id));
      this.$hideLoading();
      if (err != null) {
        console.log("加载错误", err);
        return;
      }

      this.info = res;
      this.inputText = res.answer
    },
    handleInput() {
      this.count = 500 - this.inputText.length
    },
    async onSubmitTap() {
      if (this.inputText.trim().length <= 0) {
        this.$showFail("请填写后提交");
        return;
      }

      const data = {
        content: this.inputText,
      };

      this.$showLoading("提交中...");
      /* eslint-disable */
      let [err, res] = await to(answer(this.info.qaId, data));
      /* eslint-enable*/
      this.$hideLoading();

      if (err) {
        this.$showFail("提交失败");
        return;
      }

      this.$showSuccess("提交成功", () => {
        this.$router.go(-1);
      });
      return;
    }
  }
}
</script>

<style scoped>
.page {
  padding: 16px;
}

.question-box {
  width: 100%;
  padding: 6px 0;
  display: flex;
  flex-direction: column;
}

.question-box img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 4px 0 0;
}

.question-box span {
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: #333333;
  display: flex;
  align-items: flex-end;
}

.question-box div {
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
}

.comment-box {
  margin-top: 16px;
  padding: 14px 12px;
  background: #f2f4f8;

}

.comment-box__input {
  width: 100%;
  height: 150px;
  border: none;
  background: #f2f4f8;
  font-size: 14px;
  font-weight: 500;
}

.comment-box__counter {
  font-size: 12px;
  text-align: right;
  color: #888;
}

.submit-btn {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  height: 36px;
  line-height: 36px;
  text-align: center;
  width: 230px;
  margin: 0 auto;
  margin-top: 38px;
  background: #f84c1e;
  border-radius: 6px;
  border: 1px solid #f84c1e;
}

.answer-btn {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  height: 36px;
  line-height: 36px;
  text-align: center;
  width: 230px;
  margin: 0 auto;
  margin-top: 38px;
  background: #c4c4c4;
  border-radius: 6px;
}
</style>